import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {List, ListItemButton, ListItemText} from '@mui/material'
import {Events} from '../object-actions/types/types'
import {isCurrent} from "../utils";


interface EventMenuItemsProps {
    entity: Events;
    canManage: boolean;
}

export const EventMenuItems: React.FC<EventMenuItemsProps> = ({entity, canManage}) => {

    const location = useLocation();
    const isActive = isCurrent(entity.starts, entity.ends);

    const EventMenuItems = [
        {screen: `/events/${entity.id}/playlists`, name: 'My Playlists'},
    ]

    EventMenuItems.push({screen: `/events/${entity.id}/request-song`, name: 'Request a Song'})
    EventMenuItems.push({screen: `/events/${entity.id}/activity`, name: 'Activity'})

    if (canManage) {
        EventMenuItems.unshift({screen: `/events/${entity.id}/invite`, name: 'Invite List'})
        EventMenuItems.push({screen: `/events/${entity.id}/recommended`, name: 'Recommended'})
        EventMenuItems.push({screen: `/events/${entity.id}/requested`, name: 'Requested'})
    } else {
        EventMenuItems.unshift({screen: `/events/${entity.id}/invites?status=accepted,requested`, name: 'Who\'s Going'})
    }


    return (
        <List>

            {canManage &&
              <React.Fragment><ListItemButton component={Link} to={`/forms/events/${entity.id}/edit`}
                                              selected={location.pathname === `/forms/events/${entity.id}/edit`}
              >
                <ListItemText primary={'Edit Event'}/>
              </ListItemButton>


                  {/* location.pathname.indexOf('/active') > -1 || isActive ?

                      <ListItemButton component={Link} to={`/events/${entity.id}`}
                                      selected={location.pathname === `/events/${entity.id}`}
                      >
                          <ListItemText primary={'End Event'}/>
                      </ListItemButton>
                      :

                      <ListItemButton component={Link} to={`/events/${entity.id}/active`}
                                      selected={location.pathname === `/events/${entity.id}/active`}
                      >
                          <ListItemText primary={'Start Event'}/>
                      </ListItemButton>
                  */}

              </React.Fragment>

            }


            {EventMenuItems.map(item => {
                return (
                    <ListItemButton
                        key={`navmenu-${item.screen}`} component={Link} to={item.screen}
                        selected={location.pathname === item.screen}
                    >
                        <ListItemText primary={item.name}/>
                    </ListItemButton>
                )
            })}

        </List>
    )
}
export default EventMenuItems
