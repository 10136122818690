import React, {useEffect} from 'react';
import {EventInvitedLanding} from "./EventLayouts";
import {Box, LinearProgress, Typography} from "@mui/material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {Events} from "../object-actions/types/types";
import {useParams} from "react-router-dom";

interface EventByAliasProps {
    viewMode: string;
}

export const EventByAlias: React.FC<EventByAliasProps> = ({viewMode}) => {
    const params = useParams();
    const alias = params.url_alias ?? '';

    const {
        error,
        focusedEvent, fetchInviteLink
    } = useFocusedContext()

    useEffect(() => {
            fetchInviteLink(alias)
        // save alias cookie in case not logged in or profile incomplete
    }, [alias]);

    let viewEl = null;
    if (error) {
        viewEl = <Box p={2} mt={7}><Typography color={'error'} variant={'subtitle2'}>{error}</Typography></Box>
    } else if (!focusedEvent) {
        viewEl = <Box p={2} mt={7}><LinearProgress/></Box>;
    } else {
        viewEl = <EventInvitedLanding entity={focusedEvent as Events} viewMode={alias}/>
    }

    return viewEl

};

export default EventByAlias;
