import React from "react";
import {IconButton} from "@mui/material";
import {Invites, Friendships} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import {Block} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useEventsContext} from "../contexts/EventsProvider";

interface BlockProps {
    entity: Invites | Friendships;
    style: any
}

const BlockInvite: React.FC<BlockProps> = ({entity, style}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {getFilteredEvents} = useEventsContext()

    const handleStatusChange = async () => {
        const value = entity.status === 'blocked' ? 'invited' : 'blocked';
        const msg = "Are you sure you want to block and report this Event"
        if (!window.confirm(msg)) {
            return false;
        }

        const response = await ApiClient.patch(`/api/invites/${entity.id}`, {status: value});
        if (response.success && response.data) {
            if (location.pathname !== `/events/invited`) {
                navigate(`/events/invited`)
            } else {
                getFilteredEvents('invited', '');
            }
        }
        if (response.error) {
            alert(response.error);
        }
    };

    return <IconButton style={style ?? null} onClick={() => handleStatusChange()}>
        <Block fontSize={'small'}/>
    </IconButton>
};

export default BlockInvite;
