import React, {useEffect} from 'react';
import {useAuth} from "../allauth/auth";
import {useParams} from "react-router-dom";
import FriendRequester from "../forms/FriendRequester";
import ApiClient from "../config/ApiClient";
import {RelEntity} from "../object-actions/types/types";
import {useSnackbar} from "notistack";
import {isCurrent} from "../utils";
import {useFocusedContext} from "../contexts/FocusedProvider";

interface EventInviterProps {

}

const EventInviter: React.FC<EventInviterProps> = () => {
    const {enqueueSnackbar} = useSnackbar()
    const me = useAuth()?.data?.user
    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;
    const {focusedEvent, fetchEvent} = useFocusedContext()

    useEffect(() => {
        if (!focusedEvent) {
            fetchEvent(params.id ?? '')
        }
    }, [])

    if (!focusedEvent) return null;

    const canManage = focusedEvent.author.id === me.id ?? (focusedEvent.cohosts && focusedEvent?.cohosts.findIndex(rel => rel.id === me.id) > -1);

    const sendInvite = async (recipient: RelEntity) => {

        const response = await ApiClient.post(`/api/invites`, {
            status: canManage ? 'invited' : 'referred',
            event: event_id,
            author: me.id,
            recipient: recipient.id
        });
        if (response.success && response.data) {
            enqueueSnackbar(`You invited ${recipient.str}`)
        }
        if (response.error) {
            enqueueSnackbar(response.error)
        }
    };

    return <FriendRequester onRequested={(val) => sendInvite(val)}/>
};

export default EventInviter;
