import React, {ChangeEvent, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {ButtonPill} from "../forms/StyledFields";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const EULADialog: React.FC = () => {
    const [acceptedEula, setEula] = useState<boolean>(!!window.localStorage.getItem(`acceptedEula`));

    if (acceptedEula) return null;

    const handleAcceptChange = async (e: ChangeEvent<HTMLInputElement> | any) => {
        localStorage.setItem('acceptedEula', 'true')
        setEula(true);
    }

    return (
        <Dialog open={!acceptedEula} disableEscapeKeyDown>
            <DialogTitle>EULA Agreement</DialogTitle>
            <DialogContent>
                Posting illicit, illegal or offensive content will get your booted and your content will be deleted.
                <br/><br/>
                Repeatedly friend requesting someone who keeps rejecting your friend request will also get your booted.
                <br /><br />
                If you have any questions or concerns, write us at djmoteinfo@gmail.com
            </DialogContent>
            <DialogActions>
                <ButtonPill
                    startIcon={<CheckCircleIcon  />}
                    onClick={handleAcceptChange}
                    variant="contained"
                >
                    I Accept
                </ButtonPill>
            </DialogActions>
        </Dialog>
    );
};

export default EULADialog;
