import React from 'react';
import {Outlet} from 'react-router-dom';
import {Box, Grid} from "@mui/material";
import BottomMenu from "../components/BottomMenu";
import {useAuth} from "../allauth/auth";
import {FocusedProvider} from "../contexts/FocusedProvider";
import {EventsProvider} from "../contexts/EventsProvider";
import DebugProvider from "../contexts/Debug";
import BackButton from "./BackButton";
import DataProvider from "../contexts/DataProvider";
import EventMenu from "../components/EventMenu";
import EulaDialog from "../components/EulaDialog";
import {getSafeAreaPadding} from "../utils";

const Layout: React.FC = () => {
    const me = useAuth()?.data?.user;

    const padding = getSafeAreaPadding('paddingTop') + getSafeAreaPadding('paddingBottom')

    return (
        <DebugProvider>
            <Grid container direction={'column'} justifyContent={'flex-start'}
                  sx={{minHeight: window.innerHeight - padding}}>

                <EulaDialog/>

                <Grid container justifyContent={'space-around'} flexWrap={'nowrap'}>
                    <Grid item flexGrow={1}>
                        <Box id={"AppContent"} style={{
                            width: '100%',
                            margin: `0 auto 60px auto`,
                            maxWidth: 1024
                        }}>
                            {me ?
                                <DataProvider>
                                    <EventsProvider>
                                        <FocusedProvider>
                                            <BackButton/>
                                            <Outlet/>
                                            <BottomMenu/>
                                            <EventMenu/>
                                        </FocusedProvider>
                                    </EventsProvider>
                                </DataProvider>
                                :
                                <FocusedProvider>
                                    <Outlet/>
                                    <BackButton/>
                                </FocusedProvider>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </DebugProvider>
    );
};

export default Layout;
