import React from 'react';
import {Box} from "@mui/material";
import FriendRequester from "../forms/FriendRequester";
import FriendsList from "../components/FriendsList";

interface FriendsProps {
    author?: number | string;
}

const Friends: React.FC<FriendsProps> = ({author}) => {

    return (
        <Box style={{minHeight: '100vh'}} p={1} mt={7}>
            <FriendRequester/>

            {/*
            <CardHeader
                avatar={<Avatar variant={'rounded'}><AddLink/></Avatar>}
                title={<Typography variant={'h3'}>Invite a Friend</Typography>}
                subheader={<Typography variant={'body2'}>Send an invite link to a friend</Typography>}
            />
            */}

            <FriendsList author={author}/>
        </Box>
    );
};

export default Friends;
