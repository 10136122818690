import React from 'react';
import {Link} from 'react-router-dom';
import {Users} from "../object-actions/types/types";
import Typography from '@mui/material/Typography';
import Avatar from "@mui/material/Avatar";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {EditOutlined} from "@mui/icons-material";
import {useAuth} from "../allauth/auth";
import {StyledBadge} from "../forms/StyledFields";
import FriendshipButton from "../forms/FriendshipButton";
import {getFullName, getUsername, makeSocialLink} from "../utils";
import {SvgIcon} from "@mui/material";
import {ReactComponent as Spotify} from '../assets/spotify.svg';
import {ReactComponent as AppleMusic} from '../assets/applemusic.svg';
import {ReactComponent as Instagram} from '../assets/instagram.svg';

interface UserCardProps {
    entity: Users;
}

const UserCard: React.FC<UserCardProps> = ({entity}) => {
    const me = useAuth()
    const isMe = entity.id === me?.data?.user.id

    return <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
        <Typography variant={'h5'}>{getFullName(entity)}
            {!entity.profile_picture && isMe && <IconButton component={Link} to={`/forms/users/${entity.id}/edit`}>
              <EditOutlined color="secondary" fontSize={'small'}/>
            </IconButton>}
        </Typography>
        {!entity.profile_picture ?
            null :
            !isMe ?
                <Grid item>
                    <Avatar sx={{width: 125, height: 125}}
                            alt={entity.username}
                            src={entity.profile_picture}/>
                </Grid>
                :
                <Grid item>
                    <IconButton component={Link} to={`/forms/users/${entity.id}/edit`}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
                        >
                            <Avatar sx={{width: 125, height: 125}}
                                    alt={entity.username}
                                    src={entity.profile_picture}/>
                        </StyledBadge>
                    </IconButton>
                </Grid>
        }
        <Typography variant={'h6'}>{getUsername(entity)}</Typography>
        {entity.bio && <Typography variant={'body1'}>{entity.bio}</Typography>}
        {entity.link_ig &&
          <Typography variant={'body1'}><SvgIcon component={Instagram} inheritViewBox/> <a href={entity.link_ig}
                                                                                           target="_blank">{makeSocialLink(entity.link_ig)}</a></Typography>}
        {entity.link_spotify &&
          <Typography variant={'body1'}><SvgIcon viewBox="0 0 496 512" component={Spotify} inheritViewBox/> <a
            href={entity.link_spotify} target="_blank">{makeSocialLink(entity.link_spotify)}</a></Typography>}
        {entity.link_apple &&
          <Typography variant={'body1'}><SvgIcon viewBox="0 0 136.46001 162.0049" component={AppleMusic}
                                                 inheritViewBox/> <a href={entity.link_apple}
                                                                     target="_blank">{makeSocialLink(entity.link_apple)}</a></Typography>}

        {!isMe &&
          <Box m={1}><FriendshipButton recipient={entity}/></Box>
        }
    </Grid>
};

export default UserCard;
