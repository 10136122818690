import React, {useState} from "react";
import {Avatar, Typography, useTheme} from "@mui/material";
import {Favorite, FavoriteBorderOutlined} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {SongRequests} from "../../object-actions/types/types";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {getUsername, makeAbsolute, timeAgo} from "../../utils";
import ApiClient from "../../config/ApiClient";
import {useSnackbar} from "notistack";
import {useDataContext} from "../../contexts/DataProvider";


// Define the expected response type for liking/unliking a song
interface LikeToggleResponse {
   detail?: string; // Adjust the type based on what the API returns
}


interface SongRequestCardProps {
   entity: SongRequests;
}


const SongRequestCard: React.FC<SongRequestCardProps> = ({ entity }) => {
   const theme = useTheme();
   const { enqueueSnackbar } = useSnackbar();
   const apiUrl =
       entity.event?.id && window.location.pathname.indexOf("/events/") === 0
           ? `/api/song_requests/by-event/${entity.event.id}?status=requested,accepted`
           : `/api/song_requests?status=requested,accepted`;
   const { fetchData } = useDataContext();
   const isLoading = useDataContext().isLoading[apiUrl];

   const [isLiked, setIsLiked] = useState<boolean>(entity.iliked ?? false);
   const [likesCount, setLikesCount] = useState<number>(entity.likes_count ?? 0);


   const likeEntity = async () => {
       if (isLoading) return;


       try {
           const response = await ApiClient.post(`/api/likes/toggle-like`, {
               type: "songrequests",
               item_id: entity.id,
               song: entity.song.id,
               event: entity.event.id,
               playlist: entity.playlist?.id,
           }) as { data: LikeToggleResponse }; // Type assertion for response


           if (response.data.detail) {
               // Update local state based on whether it was liked or unliked
               if (isLiked) {
                   setLikesCount((prevCount) => prevCount - 1);
               } else {
                   setLikesCount((prevCount) => prevCount + 1);
               }
               setIsLiked(!isLiked); // Toggle the liked state


               // Refetch data if needed
//               const pagination = parsePagiFiltersFromURL();
//               fetchData(apiUrl, (): any => fetchList(apiUrl, pagination), true, false);
           } else {
               enqueueSnackbar(`Error liking ${entity.song.str}`, { variant: "error" });
           }
       } catch (error) {
           console.error("Error liking song:", error);
           enqueueSnackbar("Failed to like the song", { variant: "error" });
       }
   };


   const headerProps: Partial<CardHeaderProps> = {};
   const event = entity.event;
   const username = getUsername(entity.author);


   if (entity.song && entity.song.img) {
       headerProps.avatar = (
           <Avatar src={makeAbsolute(entity.song.img)} alt={entity.song.str} variant={"rounded"} />
       );
   } else if (typeof event.img === "string") {
       headerProps.avatar = (
           <Avatar component={Link} to={`/events/${event.id}`} src={makeAbsolute(event.img)} variant={"rounded"} alt={username} />
       );
   } else if (entity.author && entity.author.img) {
       headerProps.avatar = (
           <Avatar src={makeAbsolute(entity.author.img)} alt={username} variant={"rounded"} />
       );
   } else {
       headerProps.avatar = (
           <Avatar component={Link} to={`/events/${event.id}`} src={event.str} alt={event.str} />
       );
   }


   headerProps.title = entity.song.str;
   headerProps.subheader = (
       <React.Fragment>
           requested by {username} at {event.str}
           <Typography color={"grey"} ml={1} variant="caption">
               {timeAgo(entity.created_at)}
           </Typography>
       </React.Fragment>
   );


   headerProps.action = (
       <React.Fragment>
           <Typography variant={"body2"} style={{ margin: "8px 5px 0 4px", display: "inline-block" }}>
               {likesCount}
           </Typography>
           {isLiked ? (
               <Favorite color={isLoading ? "disabled" : "primary"} onClick={likeEntity} />
           ) : (
               <FavoriteBorderOutlined onClick={likeEntity} />
           )}
       </React.Fragment>
   );


   return (
       <CardHeader
           style={{ borderBottom: `1px solid ${theme.palette.divider}`, width: "100%" }}
           key={`entity-${entity.id}-${entity._type}`}
           {...headerProps}
       />
   );
};


export default SongRequestCard;
