import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {EntityTypes, Users} from "../object-actions/types/types";
import Typography from '@mui/material/Typography';
import ApiClient from "../config/ApiClient";
import EntityList from "./EntityList";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useAuth} from "../allauth/auth";
import UserCard from "../components/UserCard";
import {LinearProgress} from "@mui/material";
import AuthMenu from "../components/AuthMenu";
import ProviderConnectButtons from "../allauth/socialaccount/ProviderConnectButtons";
import {getModelName} from "../utils"
import {useSnackbar} from "notistack";
import BlockUser from "../components/BlockUser";


const UserView: React.FC = () => {
    const location = useLocation();
    const me = useAuth()?.data?.user
    const params = useParams();
    const uid = params.uid ? parseInt(params.uid) : me.id;
    const navigate = useNavigate();
    const [userProfile, updateUserProfile] = React.useState<Users | null>(null);
    const [stats, updateStats] = React.useState<{ [key: string]: { count: number, expanded: boolean } }>({});
    const {enqueueSnackbar} = useSnackbar()
    const isMe = me && uid && me.id === uid

    useEffect(() => {
        const fetchUserProfile = async () => {
            const response = await ApiClient.get(`/api/users/${uid}${location.search}`);
            if (response.error) {
                return enqueueSnackbar(response.error)
            }
            if (response.success && response.data) {
                updateUserProfile(response.data as EntityTypes)
            }
        }
        fetchUserProfile()

    }, [location.pathname, location.search]);

    useEffect(() => {
        const newstats = {...stats}
        const fetchStats = async (model: string) => {
            const response = await ApiClient.get(`/api/users/${uid}/${model.toLowerCase()}/stats${location.search}`);
            if (response.error) {
                return enqueueSnackbar(response.error)
            }
            if (response.success && response.data) {
                // @ts-ignore
                newstats[model] = response.data
            }
        };

        const updateAllStats = async () => {
            const fetchPromises = ['Playlists', 'Friendships', 'Events'].map(async (item) => {
                await fetchStats(item);
            });

            await Promise.all(fetchPromises);

            updateStats(newstats);
        };

        updateAllStats()

        console.log("STATS", stats);
    }, [uid]);

    if (!userProfile) return null;

    const toggleStats = (model: string) => {
        if (isMe && model === 'friendships') {
            return navigate(`/friendships`)
        }

        if (!stats[model].count) return;
        const newstats = {...stats}
        newstats[model].expanded = !newstats[model].expanded
        updateStats(newstats);
    };

    if (!userProfile) return <LinearProgress/>

    return <Box mt={7} style={{minHeight: '100vh'}}>
        {!isMe &&
            <BlockUser entity={userProfile} style={{position:'absolute', right:8, top:14}} />
        }

        <UserCard entity={userProfile}/>
        <Grid container justifyContent={'space-between'} mt={4} style={{textAlign: 'center'}}>
            {Object.keys(stats).map(model_name => {
                return <Grid item xs={3} onClick={() => toggleStats(model_name)}>
                    <Typography variant={'h6'}>{stats[model_name].count}</Typography>
                    <Typography variant={'body2'}>{getModelName(model_name)}</Typography>
                </Grid>
            })}
        </Grid>

        {Object.keys(stats).map(model_name => {
            if (!stats[model_name].expanded) return null;
            return <EntityList author={uid} model={model_name}
                               search_filters={model_name === 'Friendships' ? 'status=accepted' : undefined}/>
        })}


        {isMe &&
          <React.Fragment>
            <Grid item p={2} xs={12}>
              <AuthMenu/>
            </Grid>
            <Grid item p={2} xs={12}>
              <ProviderConnectButtons/>
            </Grid>
          </React.Fragment>
        }

    </Box>

};

export default UserView;
