import React, {useState} from 'react';
import {CircularProgress} from "@mui/material";
import CardHeader, {CardHeaderProps} from '@mui/material/CardHeader';
import {Link} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import {useAuth} from "../allauth/auth";
import {FriendButton} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {getFullName, getUsername, makeAbsolute} from "../utils";
import {Friendships, Invites} from "../object-actions/types/types";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useSnackbar} from "notistack";
import {DisabledByDefault} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface FriendInviteProps {
    entity: Friendships;
    invite?: Invites;
    event_id: string | number;
    onClick: (id: number | string) => void;
    selected: boolean;
}

const FriendInvite: React.FC<FriendInviteProps> = ({entity, event_id, invite, onClick, selected = false}) => {
    const [syncing, setSyncing] = useState<boolean>(false);
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()
    const {fetchGuestList} = useFocusedContext()
    const other = entity['recipient'] && entity['author'].id === me.id ? entity['recipient'] : entity['author']

    const sendInvite = async () => {
        if (onClick) {
            return onClick(other.id)
        }
        setSyncing(true)
        const response = await ApiClient.post(`/api/invites`, {
            status: 'invited',
            event: event_id,
            author: me.id,
            recipient: other.id
        });
        setSyncing(false)
        if (response.success && response.data) {
            enqueueSnackbar(`You invited ${getUsername(entity)}`)
            fetchGuestList(event_id)
        }
        if (response.error) {
            enqueueSnackbar(response.error)
        }
    };

    const headerProps: Partial<CardHeaderProps> = {};

    if (typeof other.img === 'string') {
        headerProps.avatar =
            <Avatar component={Link} to={`/users/${other.id}`} src={makeAbsolute(other.img)}
                    alt={other['str']}/>
    } else {
        headerProps.avatar = <Avatar component={Link} to={`/users/${other.id}`} src={other['str']} alt={other['str']}/>
    }

    headerProps.title = getFullName(other)
    headerProps.subheader = getUsername(other)
    if (invite?.status) {
        headerProps.action = <FriendButton
            size={'small'}
            variant={'outlined'}
            color={'inherit'}
            disabled={true}
            startIcon={invite?.status === 'accepted' || invite?.status === 'invited' ?
                <CheckCircleIcon color="primary"/> : <DisabledByDefault/>}
        >
            {invite?.status}
        </FriendButton>
    } else {
        headerProps.action = <FriendButton
            size={'small'}
            variant={'outlined'}
            color={'inherit'}
            disabled={syncing || invite?.status === 'declined'}
            startIcon={selected ? <CheckCircleIcon color="primary"/> : (syncing ?
                <CircularProgress size={'small'} color={'primary'}/> : undefined)}
            onClick={sendInvite}
        >
            Invite
        </FriendButton>
    }


    return <CardHeader  {...headerProps} />
}

export default FriendInvite;
