import React, {useEffect, useMemo, useState} from 'react';
import {Autocomplete, CircularProgress, TextField} from '@mui/material';
import {debounce} from "../object-actions/forms/AutocompleteField";
import ApiClient from "../config/ApiClient";
import {useSnackbar} from "notistack";

// Define the type for the address option
interface AddressOption {
    label: string;
    value: string;
}

export interface AddressAutocompleteProps {
    onSelect: (selected: string) => void;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({onSelect}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<AddressOption[]>([]);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar()

    const debounceFetch = useMemo(
        () => debounce((search: string) => fetchOptions(search), 250),
        []
    );

    useEffect(() => {
        if (inputValue.trim() !== '') {
            debounceFetch(inputValue);
        } else {
            setOptions([]);
        }
    }, [inputValue, debounceFetch]);

    const handleChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        setInputValue(value || '');
    };

    function Api2Options(data: { [key: string]: string }[]): AddressOption[] {
        if (!data) return [];
        return data.map(prediction => ({
            label: prediction.description,
            value: prediction.place_id
        }));
    }

    const fetchOptions = async (search: string) => {
        setLoading(true);

        const payload: any = {
            "input": search
        }

        /* TODO: draw from Navigator if permisisons exists
        if (false) {
            payload.locationBias = {
                "circle": {
                    "center": {
                        "latitude": 0,
                        "longitude": 0
                    },
                    "radius": 0
                }
            }
        }
         */

        const response = await ApiClient.post(`/connectors/google/places`, payload);
        if (response.success && response.data) {
            setOptions(response.data as AddressOption[]);
            setLoading(false)
        } else if (response.error) {
            setLoading(false)
            enqueueSnackbar(response.error)
        }

    }

    return (
        <Autocomplete
            fullWidth={true}
            freeSolo
            options={options}
            onInputChange={handleChange}
            loading={loading}
            onChange={(event, newValue) => {
                // @ts-ignore
                onSelect(newValue.value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Location"
                    variant="standard"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AddressAutocomplete;
