import React from 'react';
import {RelEntity} from "../object-actions/types/types";
import AutocompleteField from "../object-actions/forms/AutocompleteField";
import {useNavigate} from "react-router-dom";

interface FriendRequestProps {
    onRequested?: (selected: RelEntity) => void;
}

const FriendRequester: React.FC<FriendRequestProps> = ({onRequested}) => {
    const navigate = useNavigate();

    const handleSelect = async (value: RelEntity | null, field_name: string) => {
        if (!value) return false;
        if (onRequested) {
            onRequested(value);
        } else {
            navigate(`/users/${value.id}`);
        }
    };

    return <React.Fragment>
        <AutocompleteField type={"Users"}
                           search_fields={[
                               "full_name",
                               "username"
                           ]}
                           image_field={'profile_picture'}
                           onSelect={handleSelect}
                           field_name={'recipient'}
                           field_label={"Users"}
                           selected={null}
        />
    </React.Fragment>
};

export default FriendRequester;