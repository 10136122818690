import React, {useState} from 'react';
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Friendships} from "../object-actions/types/types";
import {useLocation} from "react-router-dom";
import Friendship from "../components/Friendship";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import LazyLoadWrapper from "./LazyLoadWrapper";

interface FriendsProps {
    author?: number | string;
}

const Friends: React.FC<FriendsProps> = ({author}) => {
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    let apiUrl = "";
    if (author) {
        apiUrl += `/api/users/${author}/friendships`
    } else {
        apiUrl += `/api/friendships`
    }
    const params = new URLSearchParams(location.search);
    params.set('status', 'accepted')
    apiUrl += `?${params.toString()}`

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<Friendships>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <Grid container>
            <LazyLoadWrapper
                onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                onLoadLess={() => handlePagination(apiResponse.offset - apiResponse.limit, apiResponse.limit)}
                hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                hasLess={apiResponse.offset - apiResponse.limit >= 0}
                threshold={50}
                isLoading={isLoading}
            >
                {apiResponse.results.map((obj, i) => <Grid xs={12} item key={`entitycard-${i}`}>
                        <Friendship entity={obj as Friendships}/>
                    </Grid>
                )}
            </LazyLoadWrapper>
        </Grid>
    );
};

export default Friends;
