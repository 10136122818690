import React from 'react';
import {Grid} from "@mui/material";
import {ApiListResponse, Events} from "../object-actions/types/types";
import TablePaginator from "../components/TablePaginator";
import {useLocation} from "react-router-dom";
import {EventShortView} from "./EventLayouts";
import MyEventCard from "../components/MyEventCard";
import {useEventsContext} from "../contexts/EventsProvider";

interface EventsListProps {
    listData: ApiListResponse;
    invite_status: string;
}

const EventsList: React.FC<EventsListProps> = ({invite_status, listData}) => {
    const location = useLocation();
    const {getFilteredEvents} = useEventsContext();


    function handlePagination(offset: number, limit: number) {

        const params = new URLSearchParams(location.search);

        // overwrite URL since this might be a sub list container
        if (offset > 0) {
            params.set('offset', offset.toString());
        }
        if (limit > 0) {
            params.set('limit', limit.toString());
        }

        getFilteredEvents(invite_status, params.toString())
    }

    return (
        <React.Fragment>
            {listData.count > listData.results.length &&
              <TablePaginator total={listData.count} limit={listData.limit} offset={listData.offset}
                              onPageChange={handlePagination}
              />
            }
            {listData.results.length > 0 &&
              <Grid container p={2} gap={2}>
                  {listData.results.map((obj, i) => <Grid xs={12} mb={3} item key={`entitycard-${i}`}>
                      {invite_status === 'mine' ?
                          <MyEventCard entity={obj as Events}/>
                          :
                          <EventShortView entity={obj as Events} viewMode={'short'}/>
                      }

                  </Grid>)
                  }
              </Grid>
            }
        </React.Fragment>
    );
};

export default EventsList;
