import React, {useState} from 'react';
import {ApiListResponse, SocialPlaylists} from "../object-actions/types/types";
import {fetchList} from "../hooks/useQuery";
import {useAuth} from "../allauth/auth";
import {Box, CircularProgress, Grid, IconButton, LinearProgress, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ButtonPill} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {useNavigate, useParams} from "react-router-dom";
import {PagiFilters, useData} from "../contexts/DataProvider";
import {SoloCenteredBox} from "../components/FullscreenBg";
import LazyLoadWrapper from "../components/LazyLoadWrapper";

const PlaylistsSpotify: React.FC = () => {

    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;
    const navigate = useNavigate();
    const [selectedPlaylists, setSelectedPlaylists] = useState<Set<string>>(new Set());
    const [syncing, setSyncing] = useState<boolean>(false);
//    const spotify = useProviderToken('spotify')
    const [pagination, setPagination] = useState<PagiFilters>({offset: 0, limit: 10});

    const me = useAuth()?.data?.user;
    const apiUrl = `/connectors/spotify/playlists?user_id=${me.id}`

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<SocialPlaylists>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));


    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    /*
        if (!spotify) return <Box p={2}>
            <Typography variant={'h3'} gutterBottom={true}>Connect Spotify</Typography>
            <ProviderConnectButtons/>
        </Box>
        */

    if (error) return <SoloCenteredBox><Typography variant={'body2'} color={"error"}>{error}</Typography>
    </SoloCenteredBox>

    const handleSelect = (id: string) => {
        setSelectedPlaylists(prev => {
            const newSelected = new Set(prev);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
            }
            return newSelected;
        });
    };

    const handlePlaylistSync = async () => {
        setSyncing(true)
        const response = await ApiClient.post(`/connectors/spotify/playlists/sync`, {
            event_id: event_id,
            pids: Array.from(selectedPlaylists)
        });

        setSyncing(false)

        if (response.error) {
            alert(response.error)
        } else {
            navigate(`/events/${event_id}`)
            console.log(response.data as ApiListResponse)
        }
    }


    return (
        <Box p={2} mb={5}>

            <Typography mt={5} variant={'h3'} gutterBottom={true} style={{textAlign: 'center'}}>Select
                Playlists</Typography>

            {error && <Typography variant={'body2'} color={"error"}>{error}</Typography>}

            <LazyLoadWrapper
                onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                onLoadLess={() => handlePagination(apiResponse.offset - apiResponse.limit, apiResponse.limit)}
                hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                hasLess={apiResponse.offset - apiResponse.limit >= 0}
                threshold={50}
                isLoading={isLoading}
            >
                <Grid container spacing={2} mb={2}>
                    {apiResponse.results.map((playlist) => {
                        const isSelected = selectedPlaylists.has(playlist.id);
                        return (
                            <Grid item xs={6} sm={4} md={3} key={playlist.id}
                                  style={{textAlign: 'center', position: 'relative'}}>
                                <Box onClick={() => handleSelect(playlist.id)}
                                     sx={{position: 'relative', cursor: 'pointer'}}>
                                    <img src={playlist.remote_image}
                                         style={{
                                             width: '100%',
                                             height: 'auto',
                                             borderRadius: 8,
                                             opacity: isSelected ? 0.7 : 1
                                         }}/>
                                    {isSelected && (
                                        <IconButton sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '3rem',
                                        }}>
                                            <CheckCircleIcon fontSize="inherit"/>
                                        </IconButton>
                                    )}
                                </Box>
                                <Typography variant={'subtitle2'}>{playlist.name}</Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </LazyLoadWrapper>
            <Box style={{textAlign: 'center'}}>
                <ButtonPill
                    disabled={syncing}
                    startIcon={syncing ? <CircularProgress size={'small'} color={'primary'}/> : undefined}
                    variant={'contained'}
                    onClick={handlePlaylistSync}
                >Confirm</ButtonPill>
            </Box>
        </Box>
    );
};

export default PlaylistsSpotify;
