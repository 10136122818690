import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {HttpResponse} from "../config/ApiClient";

interface DataContextType {
    dataStore: Record<string, any>;
    errorStore: Record<string, string | null>;
    isLoading: Record<string, boolean>;
    fetchData: (key: string, fetchFunction: () => Promise<HttpResponse>, refetch: boolean) => Promise<HttpResponse>;
}

export interface PagiFilters {
    offset?: number;
    limit?: number;
    status?: string
    refresh?: number;

    [key: string]: any; // Allow for additional dynamic keys
}

// Function to parse query parameters from the URL
export function parsePagiFiltersFromURL(): PagiFilters {
    const searchParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.slice(1)); // Slice to remove the leading '#'

    const offset = searchParams.get('offset') ? parseInt(searchParams.get('offset')!, 10) : undefined;
    const limit = searchParams.get('limit') ? parseInt(searchParams.get('limit')!, 10) : undefined;
    const status = searchParams.get('status') || undefined;
    const refresh = hashParams.get('refresh') ? parseInt(hashParams.get('refresh')!, 10) : undefined;

    const filters: PagiFilters = {
        offset,
        limit,
        status,
        refresh,
    };

    // Handle any hash parameters that start with "refresh-%"
    hashParams.forEach((value, key) => {
        if (key.startsWith('refresh-')) {
            const [baseKey, ...rest] = key.split('-');
            if (rest.length > 0) {
                filters[baseKey] = parseInt(value, 10) || value;
            }
        }
    });

    return filters;
}

// Create the context with a default value
export const DataContext = createContext<DataContextType | undefined>(undefined);

// Define the DataProvider component props
interface DataProviderProps {
    children: ReactNode;
}

// DataProvider component
const DataProvider: React.FC<DataProviderProps> = ({children}) => {
    const [dataStore, setDataStore] = useState<Record<string, any>>({});
    const [errorStore, setErrorStore] = useState<Record<string, string | null>>({});
    const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
    const fetchData = async (key: string, fetchFunction: () => Promise<HttpResponse>, refetch: boolean = false): Promise<HttpResponse> => {
        // Check if data for the key already exists
        if (dataStore[key] && !refetch) return dataStore[key];

        // Set loading state for the key before fetching
        setIsLoading((prevIsLoading) => ({...prevIsLoading, [key]: true}));

        try {
            // Fetch data and save it to the context state
            const data = await fetchFunction();
            if (typeof data.error === 'string') {
                setErrorStore((prevDataStore) => ({
                    ...prevDataStore,
                    // @ts-ignore
                    [key]: data?.error.toString()
                }));
            } else {
                setDataStore((prevDataStore) => ({
                    ...prevDataStore,
                    [key]: data.data,
                }));
                setErrorStore((prevDataStore) => ({
                    ...prevDataStore,
                    [key]: null,
                }));
            }
            setIsLoading((prevIsLoading) => ({...prevIsLoading, [key]: false}));
            return data;
        } finally {
            // Set loading state for the key after fetching
            setIsLoading((prevIsLoading) => ({...prevIsLoading, [key]: false}));
        }
    };

    return (
        <DataContext.Provider value={{dataStore, errorStore, isLoading, fetchData}}>
            {children}
        </DataContext.Provider>
    );
};


export const useData = <T, >(key: string, pagination: PagiFilters, fetchFunction: <T> () => Promise<HttpResponse<T>>): {
    apiResponse: T;
    error: string | null;
    isLoading: boolean
} => {
    const {dataStore, errorStore, isLoading, fetchData} = useDataContext();
    const location = useLocation();

    useEffect(() => {
        if (!isLoading[key]) {
            if (!dataStore[key]) {
                fetchData(key, fetchFunction, false);
            } else {
                const currentPagiFilters = parsePagiFiltersFromURL();
                if (dataStore[key].limit !== pagination.limit || dataStore[key].offset !== pagination.offset) {
                    fetchData(key, fetchFunction, true);
                } else if (pagination.refresh !== currentPagiFilters.refresh) {
                    fetchData(key, fetchFunction, true);
                }
            }
        }
    }, [key, pagination, location]);

    const base = {
        apiResponse: {},
        error: null,
        isLoading: isLoading[key] || !dataStore[key],
    }

    if (typeof errorStore[key] === 'string') {
        // @ts-ignore
        base.error = errorStore[key]
    }

    if (dataStore[key]) {
        // @ts-ignore
        base.apiResponse = dataStore[key] as T
    }
    // @ts-ignore
    return base
};

export const useDataContext = (): DataContextType => {
    const context = useContext(DataContext);

    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }

    return context;
};

export default DataProvider
