import React from "react";
import {Box,} from "@mui/material";
import SongRequestList from "./activities/SongRequestList";
import SongRequester from "./SongRequester";

interface EventSongRequestsProps {
    event_id?: string | number;
}

const EventSongRequests: React.FC<EventSongRequestsProps> = ({event_id}) => {

    return (
        <Box style={{minHeight: '100vh'}} mt={8}>
            <SongRequester event_id={event_id}/>
            <SongRequestList event_id={event_id}/>
        </Box>
    );
}

export default EventSongRequests;
