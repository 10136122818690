import React, {useState} from 'react';
import Cropper from 'react-easy-crop';
import {Box, Button, Grid, Slider, Typography} from '@mui/material';
import {getCroppedImg, blobToFile, PixelCrop} from "../../utils";

interface CropperComponentProps {
    imageSrc: string;
    onDone: (url: string, file: Blob) => void;
    onChangeImage: () => void;
    onCancel: () => void;
}

const CropperComponent: React.FC<CropperComponentProps> = ({imageSrc, onDone, onChangeImage, onCancel}) => {
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState<number>(0)
    const [zoom, setZoom] = useState<number>(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>()

    const onCropComplete = (croppedArea: PixelCrop, croppedAreaPixels: PixelCrop) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        if (!croppedAreaPixels) return false;
        try {
            const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels, rotation)
            console.log(`done cropping  ${croppedImageUrl}`)
            if (croppedImageUrl) {
                const response = await fetch(croppedImageUrl);
                const blob = await response.blob();
                const file = blobToFile(blob, imageSrc);
                onDone(croppedImageUrl, file);
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Grid container flexDirection={'column'} direction={'column'} justifyContent={'space-between'} wrap={'nowrap'}
              style={{position: 'relative', height: '95vh'}}>
            <Grid item xs={12} justifyContent={'space-between'} container>
                <Grid item>
                    <Button
                        onClick={onChangeImage}
                        variant="text"
                        color="primary"
                    >
                        Change Image
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onCancel}
                        variant="text"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} flexGrow={1} >
                <Grid container direction={'column'} justifyContent={'space-between'} wrap={'nowrap'}
                      style={{position: 'relative', width: '100%', height: '70vh'}}>
                    <Grid item xs={12} style={{position: 'relative', width: '100%', height: '40vh'}}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            objectFit={'cover'}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </Grid>
                    {/* *
                    <Grid item xs={12}>
                        <Box style={{position: 'relative'}} mb={2}>
                            <Typography
                                style={{position: 'absolute', bottom: 0, right: 0}}
                                variant="overline"
                            >
                                Zoom
                            </Typography>
                            <Slider
                                value={zoom}
                                size={'small'}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => {
                                    // @ts-ignore
                                    setZoom(zoom);
                                }}
                            />
                        </Box>
                        <Box style={{position: 'relative'}} mb={2}>
                            <Typography variant="overline" style={{position: 'absolute', bottom: 0, right: 0}}>
                                Rotation
                            </Typography>
                            <Slider
                                value={rotation}
                                size={'small'}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                onChange={(e, rotation) => {
                                    // @ts-ignore
                                    setRotation(rotation);
                                }}
                            />
                        </Box>
                    </Grid>
                    */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={showCroppedImage}
                    variant="text"
                    color="primary"
                    fullWidth={true}
                >
                    DONE
                </Button>
            </Grid>
        </Grid>
    );
};


export default CropperComponent;
