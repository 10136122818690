import React, {useState} from "react";
import {Avatar, Box, Grid, LinearProgress, Typography,} from "@mui/material";
import {ApiListResponse, Songs} from "../object-actions/types/types";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {Link} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";

interface SongRecommendationsProps {
    event_id?: string | number;
}

const SongRecommendations: React.FC<SongRecommendationsProps> = ({event_id}) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    let apiUrl = `/api/events/${event_id}/song-recommendations`
    const params = new URLSearchParams();
    apiUrl += `?${params.toString()}`

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<Songs>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    if (apiResponse?.count === 0) return <Box p={2} mt={5} style={{textAlign: 'center'}}><Typography component={Link}
                                                                                                     to={`/events/${event_id}/playlists`}
                                                                                                     variant={'body1'}
                                                                                                     color={'primary'}>Sync
        your playlists to get song recommendations</Typography></Box>

    return (
        <Box p={1} mt={2}>
            <Typography variant={'h3'} gutterBottom={true}>Recommended Songs</Typography>
            <Grid container>
                {apiResponse?.results.map((song, idx) => {

                    const headerProps: Partial<CardHeaderProps> = {};

                    if (typeof song.remote_image === 'string') {
                        headerProps.avatar =
                            <Avatar src={song.remote_image} variant={'rounded'}
                                    alt={song.name}/>
                    }

                    headerProps.title = <Typography variant={"body1"}>{song.name}</Typography>
                    headerProps.subheader = <Typography variant={"body2"}>{song.artist}</Typography>
                    headerProps.action = <Typography variant={'overline'} p={1} sx={{
                        borderRadius: 8,
                        backgroundColor: 'primary.main'
                    }}>{song.listener_count}</Typography>

                    return <CardHeader key={`song-${song.name}-${song.artist}`} style={{width: '100%'}}
                                       {...headerProps} />
                })}
            </Grid>
        </Box>
    );
}

export default SongRecommendations;
